import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { Theme } from './theme.type';

interface IThemeState {
  theme: Theme;
  system: boolean;
}

const initialState = { theme: Theme.SYSTEM, system: true } as IThemeState;

export const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setTheme(state, { payload }: PayloadAction<Theme>) {
      state.theme = payload;
    },
    setSystem(state, { payload }: PayloadAction<boolean>) {
      state.system = payload;
    },
  },
});

export const { setTheme, setSystem } = themeSlice.actions;

export const themeSelector = (state: RootState): IThemeState => state.theme;

export const selectTheme = createSelector(themeSelector, (t) => t.theme);
export const selectSystem = createSelector(themeSelector, (t) => t.system);

export default themeSlice.reducer;