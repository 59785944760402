export type DragonUserID = string;
export type DragonGroupID = string;
export type DragonGroupName = string;

export enum DragonUserRole {
  Admin = 'admin',
  User = 'user',
}

export interface DragonUser {
  id: DragonUserID;
  first_name: string;
  last_name: string;
  email: string;
  groups: DragonGroupID[];
  role: DragonUserRole;
}

export interface DragonGroup {
  id: DragonGroupID;
  name: DragonGroupName;
}

export interface DragonGroupInfo extends DragonGroup {
  users: DragonUserID[];
}

export interface IUsersAndGroupsState {
  users: Record<DragonUserID, DragonUser>;
  groups: Record<DragonGroupID, DragonGroup>;
};