import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { IUserInfoState, UserProfile } from './user-info.types';
import { DragonUserRole } from './users-and-groups.types';

export const initialState: IUserInfoState = {
  profile: {
    id: '',
    first_name: '',
    last_name: '',
    email: '',
    company: '',
    role: DragonUserRole.User
  },
};

export const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {
    setProfile(state, { payload }: PayloadAction<UserProfile>) {
      if (payload) state.profile = payload;
    },
  },
});

export const {setProfile } = userInfoSlice.actions;

export const servicesSelector = (state: RootState): IUserInfoState => state.userInfo;

export const selectProfile = createSelector(servicesSelector, (services) => services.profile);