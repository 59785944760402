import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Axios } from 'axios';
import { Store } from 'react-notifications-component';

import { ProgressionHandler, uploadKBFile } from '../../services/file-upload';
import { createid } from '../../services/id';
import { addNotification } from '../../services/notification';
import { RootState } from '../store';
import { FileUploadStats, IKnowledgeBasesState, KnowledgeBase, KnowledgeBaseAdmin, KnowledgeBaseFolderID, KnowledgeBaseID, ProgressActionPayload, WithUploadId } from './knowledge-bases.type';

export const uploadKBFileThunk = createAsyncThunk<
  void,
  { file: File; axios: Axios; folderId: KnowledgeBaseFolderID; onUpdateAfterUpload: () => void },
  {
    rejectValue: string;
  }
>(
  'uploadKBFile',
  async ({ file, axios: _axios, folderId, onUpdateAfterUpload }, { dispatch }) => {
    const { name } = file;
    const uploadid = createid();

    const onProgress: ProgressionHandler = (progress) =>
      dispatch(setUploadProgress({ progress, uploadid }));

    const { promise } = uploadKBFile(
      file,
      _axios,
      folderId,
      onProgress
    );

    dispatch(addKBUpload({ uploadid, name, progress: 0 }));

    try {
      await promise;
      dispatch(removeUpload({ uploadid }));
      onUpdateAfterUpload();
    } catch (e) {
      if (e instanceof Error) {
        addNotification(
          Store,
          `An error occured while analysing ${name}`,
          e.message,
          'danger'
        );
        dispatch(removeUpload({ uploadid }));
      } else {
        console.log('Unexpected error', e);
      }
    }
  }
);

export const initialState: IKnowledgeBasesState = {
  adminknowledgeBases: {},
  chatKnowledgeBases: {},
  chatSelectedKnowledgeBase: undefined,
  uploads: {},
};

export const knowledgeBasesSlice = createSlice({
  name: 'knowledgeBases',
  initialState,
  reducers: {
    addKBUpload(state, { payload }: PayloadAction<FileUploadStats>) {
      state.uploads = { // eslint-disable-line
        ...state.uploads,
        [payload.uploadid]: payload, // eslint-disable-line
      };
    },
    removeUpload(
      state,
      { payload: { uploadid } }: PayloadAction<WithUploadId>
    ) {
      delete state.uploads[uploadid]; // eslint-disable-line
    },
    setUploadProgress(
      state,
      { payload: { uploadid, progress } }: PayloadAction<ProgressActionPayload>
    ) {
      const uploaded = state.uploads[uploadid]; // eslint-disable-line
      if (!uploaded) return;
      uploaded.progress = progress; // eslint-disable-line
    },
    setAdminKnowledgeBases(state, { payload }: PayloadAction<KnowledgeBaseAdmin[]>) {
      // Explicitly assert the type of `payload`
      const knowledgeBases: Record<KnowledgeBaseID, KnowledgeBaseAdmin> = {};

      if (payload && payload.length > 0) {
        payload.forEach((kb: KnowledgeBaseAdmin) => {
          knowledgeBases[kb.id as KnowledgeBaseID] = kb; // eslint-disable-line
        });
        state.adminknowledgeBases = knowledgeBases;
      } else {
        state.adminknowledgeBases = {};
      }
    },
    setChatKnowledgeBases(state, { payload }: PayloadAction<KnowledgeBase[]>) {
      // Explicitly assert the type of `payload`
      const knowledgeBases: Record<KnowledgeBaseID, KnowledgeBase> = {};
	
      if (payload && payload.length > 0) {
        payload.forEach((kb: KnowledgeBase) => {
          knowledgeBases[kb.id as KnowledgeBaseID] = kb; // eslint-disable-line
        });
        state.chatKnowledgeBases = knowledgeBases;
      } else {
        state.chatKnowledgeBases = {};
      }
    },
    addKnowledgeBaseToStore(state, { payload }: PayloadAction<KnowledgeBaseAdmin>) {
      state.adminknowledgeBases[payload.id] = payload;
    },
    setKBRootFolder(state, { payload: {kbID, rootFolder} }: PayloadAction<{kbID: KnowledgeBaseID} & {rootFolder: KnowledgeBaseFolderID}>) {
      state.adminknowledgeBases[kbID].root_folder = rootFolder;
    },
    deleteKNowledgeBaseFromStore(state, { payload }: PayloadAction<KnowledgeBaseID>) {
      delete state.adminknowledgeBases[payload];
    },
    updateKnowledgeBaseNameOrDescription(state, { payload: {kbID, name, description} }: PayloadAction<{kbID: KnowledgeBaseID} & {name?: string} & {description?: string}>) {
      if(name !== undefined) state.adminknowledgeBases[kbID].name = name;
      if(description !== undefined) state.adminknowledgeBases[kbID].description = description;
    },
    setChatKnowledgeBase(state, { payload }: PayloadAction<KnowledgeBase | undefined>) {
      state.chatSelectedKnowledgeBase = payload;
    },
  },
});

export const { removeUpload, addKBUpload, setUploadProgress, setAdminKnowledgeBases, deleteKNowledgeBaseFromStore, updateKnowledgeBaseNameOrDescription, setChatKnowledgeBase, setChatKnowledgeBases, addKnowledgeBaseToStore, setKBRootFolder } = knowledgeBasesSlice.actions;

export const usersAndGroupsSelector = (state: RootState): IKnowledgeBasesState => state.knowledgeBases;

export const selectAdminKnowledgeBases = createSelector(usersAndGroupsSelector, (knowledgeBases) => knowledgeBases.adminknowledgeBases);
export const selectChatKnowledgeBases = createSelector(usersAndGroupsSelector, (knowledgeBases) => knowledgeBases.chatKnowledgeBases);
export const selectChatSelectedKnowledgeBase = createSelector(usersAndGroupsSelector, (knowledgeBases) => knowledgeBases.chatSelectedKnowledgeBase);
export const selectKBUploads = createSelector(usersAndGroupsSelector, (knowledgeBases) => knowledgeBases.uploads === undefined ? [] : Object.values(knowledgeBases.uploads)); // eslint-disable-line
