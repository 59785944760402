import './index.scss';
import './i18n';
import './fonts/VAGRoundedBT.ttf';

import { ReactKeycloakProvider } from '@react-keycloak/web';
import React, { FC } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import { LCAPIAxiosProvider } from './components/LCAPIAxios.provider';
import { ToastProvider } from './components/ToastProvider';
import keycloak from './Keycloak';
import { reportWebVitals } from './reportWebVitals';
import { AppRouter } from './router/router';
import { persistor, store } from './store/store';

if (process.env.REACT_APP_USE_MOCK) {
  // eslint-disable-next-line
  const { worker } = require(process.env.REACT_APP_USE_MOCK);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  worker.start();
}

const queryClient = new QueryClient();

const DevKeyCloakProvider: FC = ({ children }) =>
  process.env.REACT_APP_USE_KEYCLOAK ? (
    <ReactKeycloakProvider
      initOptions={{ onLoad: 'login-required', checkLoginIframe: false }}
      authClient={keycloak} // eslint-disable-line
      LoadingComponent={
        <div className="vw-100 vh-100 d-flex justify-content-center align-items-center">
          <Spinner animation="border" />
        </div>
      }
    >
      {children}
    </ReactKeycloakProvider>
  ) : (
    <>{children}</>
  );

ReactDOM.render(
  <React.StrictMode>
    <DevKeyCloakProvider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <QueryClientProvider client={queryClient}>
            <BrowserRouter>
              <DndProvider backend={HTML5Backend}>
                <LCAPIAxiosProvider>
                  <ToastProvider>
                    <AppRouter />
                    <ReactQueryDevtools
                      initialIsOpen={false}
                      position="bottom-right"
                    />
                  </ToastProvider>
                </LCAPIAxiosProvider>
              </DndProvider>
            </BrowserRouter>
          </QueryClientProvider>
        </PersistGate>
      </Provider>
    </DevKeyCloakProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

//    initOptions={{ onLoad: 'login-required' }}
