import axios, { Axios, Canceler } from 'axios';

import { KnowledgeBaseFolderID } from '../store/reducers/knowledge-bases.type';

// import { DAChatId } from '../store/reducers/chat.types';

export enum FileUploadWarning {
  NOT_SUPPORTED = 'Document type not supported',
  TOO_BIG = 'File is too big',
  WRONG_LANGUAGE = 'Language is not supported',
  TOO_MANY_CHARACTERS = 'Too many characters',
  NONE = '',
}

export interface FileUploadResult {
  id: string;
  // language: Language;
  length: number;
  type: string;
  warning: FileUploadWarning; // ex: 'Document type not supported'
}

const progressionHandlerWrapper =
  (progress: (progress: number) => void) => (e: ProgressEvent) =>
    e.lengthComputable && progress(e.loaded / e.total);

export type ProgressionHandler = (progress: number) => void;

export function uploadFile<T>(
  url: string,
  payload: Record<string, string | Blob>,
  progress?: ProgressionHandler,
  _axios: Axios = axios
): {
  cancel: Canceler;
  promise: Promise<T>;
} {
  const form = new FormData();
  for (const name in payload) {
    const value = payload[name];
    form.append(name, value);
  }

  const { cancel, token } = axios.CancelToken.source();
  const isTestEnv = process.env.NODE_ENV === 'test';
  const onUploadProgress = !isTestEnv
    ? progress && progressionHandlerWrapper(progress)
    : undefined;

  const promise = _axios
    .post<T>(url, form, {
      cancelToken: token,
      onUploadProgress,
    })
    .then(({ data }) => data)
    .catch(error => {
      if (error.response) {                           // eslint-disable-line
        // API answered with an error
        if (error.response.data) {                    // eslint-disable-line
          throw new Error(error.response.data)        // eslint-disable-line
        }
        else {
          throw new Error(error.response.statusText)  // eslint-disable-line
        }
      }
      else {
        throw new Error('Unknown Error')
      }
    });
  return { cancel, promise };
}

export function uploadKBFile(
  file: File,
  _axios: Axios,
  folderId?: KnowledgeBaseFolderID,
  progress?: ProgressionHandler,
): {
  cancel: Canceler;
  promise: Promise<FileUploadResult>;
}{
  return uploadFile<FileUploadResult>(
    folderId ? `/admin/knowledge/folders/${folderId}/upload` : '/admin/knowledge',
    { file },
    progress,
    _axios
  );
}

export {}