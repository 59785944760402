import React from 'react';
import { RouteObject } from 'react-router-dom';

import { suspense } from './utils';

const App                         = React.lazy(() => import('../App/App')); // prettier-ignore
const NotFound                    = React.lazy(() => import('../pages/NotFound/NotFound')); // prettier-ignore
const Administration              = React.lazy(() => import('../pages/Administration/Dashboard/Dashboard')); // prettier-ignore
const DragonChat            = React.lazy(() => import('../pages/Application/DragonChat/LcLlm')); // prettier-ignore
const KnowledgeManagementCentre   = React.lazy(() => import('../pages/Application/KnowledgeManagement/KnowledgeManagement')); // prettier-ignore
const HelpAdministration          = React.lazy(() => import('../pages/Administration/HelpAdministration/HelpAdministration')); // prettier-ignore
const UsersAndGroupsManagement              = React.lazy(() => import('../pages/Administration/UserManagement/UsersAndGroupsManagement')); // prettier-ignore
const KnowledgeBases              = React.lazy(() => import('../pages/Administration/KnowledgeBases/KnowledgeBases')); // prettier-ignore

export interface AppRoute<P extends unknown[] = never[]> {
  name: string;
  path: (...args: P) => string;
}

function typeRoutes<T extends Record<string, AppRoute>>(
  routes: T
): { [K in keyof T]: T[K] } {
  return routes;
}

export const routes = typeRoutes({
  DragonChat: {
    name: '',
    path() {
      return '/';
    },
  },
  KnowledgeManagementCentre: {
    name: 'KnowledgeManagementCentre',
    path() {
      return '/KnowledgeManagementCentre';
    }
  },
  Administration: {
    name: '/Administration/Dashboard',
    path() {
      return '/Administration/Dashboard';
    },
  },
  UsersAndGroupsManagement: {
    name: '/Administration/UsersAndGroupsManagement',
    path() {
      return '/Administration/UsersAndGroupsManagement';
    },
  },
  KnowledgeBases: {
    name: '/Administration/KnowledgeBases',
    path() {
      return '/Administration/KnowledgeBases';
    },
  },
  HelpAdministration: {
    name: '/Administration/Help',
    path() {
      return '/Administration/Help';
    },
  },
});

export const routerRoutes: RouteObject[] = [
  {
    element: suspense(App),
    children: [
      {
        path: routes.DragonChat.name,
        element: suspense(DragonChat),
      },
      {
        path: routes.KnowledgeManagementCentre.name,
        element: suspense(KnowledgeManagementCentre),
      },
      {
        path: routes.Administration.name,
        element: suspense(Administration),
      },
      {
        path: routes.UsersAndGroupsManagement.name,
        element: suspense(UsersAndGroupsManagement),
      },
      {
        path: routes.KnowledgeBases.name,
        element: suspense(KnowledgeBases),
      },
      {
        path: routes.HelpAdministration.name,
        element: suspense(HelpAdministration),
      },
    ],
  },
  {
    path: '*',
    element: suspense(NotFound),
  },
];
