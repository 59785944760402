import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { DragonGroup, DragonGroupID, DragonGroupName, DragonUser, DragonUserID, DragonUserRole, IUsersAndGroupsState} from './users-and-groups.types';

export const initialState: IUsersAndGroupsState = {
  users: {},
  groups: {}
};

export const usersAndGroupsSlice = createSlice({
  name: 'usersAndGroups',
  initialState,
  reducers: {
    setDragonUsers(state, { payload }: PayloadAction<DragonUser[]>) {
      const users:Record<DragonUserID, DragonUser> = {};
      if(payload.length > 0) {
        payload.map(u => {
          users[u.id] = u;
        })
        state.users = users
      } else {
        state.users = {};
      }
    },
    setDragonGroups(state, { payload }: PayloadAction<DragonGroup[]>) {
      const groups:Record<DragonGroupID, DragonGroup> = {};
      if(payload.length > 0) {
        payload.map(g => {
          groups[g.id] = g;
        })
        state.groups = groups
      } else {
        state.groups = {};
      }
    },
    deleteUserFromStore(state, { payload }: PayloadAction<DragonUserID>) {
      delete state.users[payload];
    },
    addGroup(state, { payload: {id, name, users} }: PayloadAction<{id: DragonGroupID} & {name: DragonGroupName} & {users: DragonUserID[]}>) {
      const newGroup:DragonGroup = {id: id, name: name};
      state.groups[id] = newGroup;
      // add group id to all users added to the new group
      if(users.length) users.forEach(u => state.users[u].groups.push(id));
    },
    deleteGroupFromStore(state, { payload }: PayloadAction<DragonGroupID>) {
      // remove group from groups in user
      delete state.groups[payload];
    },
    updateGroupName(state, { payload: {group, name} }: PayloadAction<{group: DragonGroupID} & {name: DragonGroupName}>) {
      state.groups[group].name = name;
    },
    // addUserToGroup
    removeUserFromGroup(state, { payload: {group, user} }: PayloadAction<{group: DragonGroupID} & {user: DragonUserID}>) {
      // remove group from groups in user
      state.users[user].groups = state.users[user].groups.filter(g => g !== group);
    },
    storeUserRole(state, { payload: {id, newRole} }: PayloadAction<{id: DragonGroupID} & {newRole: DragonUserRole}>) {
      state.users[id].role = newRole;
    },
    addGroupForUser(state, { payload: {userId, groupId} }: PayloadAction<{userId: DragonUserID} & {groupId: DragonUserID}>) {
      state.users[userId].groups.push(groupId);
    },
    removeGroupForUser(state, { payload: {userId, groupId} }: PayloadAction<{userId: DragonUserID} & {groupId: DragonUserID}>) {
      state.users[userId].groups = state.users[userId].groups.filter(g => g !== groupId);
    },
  },
});

export const {removeGroupForUser, addGroupForUser, setDragonUsers, setDragonGroups, removeUserFromGroup, deleteGroupFromStore, updateGroupName, addGroup, deleteUserFromStore, storeUserRole } = usersAndGroupsSlice.actions;

export const usersAndGroupsSelector = (state: RootState): IUsersAndGroupsState => state.usersAndGroups;

export const selectDragonUsers = createSelector(usersAndGroupsSelector, (usersAndGroups) => usersAndGroups.users);
export const selectDragonGroups = createSelector(usersAndGroupsSelector, (usersAndGroups) => usersAndGroups.groups);
