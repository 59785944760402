import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// import { chatSlice } from './reducers/chat-slice';
import { filterSlice } from './reducers/filter-slice';
import { firstLoginSlice } from './reducers/firstLogin-slice';
import { knowledgeBasesSlice } from './reducers/knowledge-bases-slice';
import { menuSlice } from './reducers/menu-slice';
import { notificationsSlice } from './reducers/notifications-slice';
// import { onboardingSlice } from './reducers/onboarding-slice';
import { themeSlice } from './reducers/theme-slice';
import { userInfoSlice } from './reducers/user-info-slice';
import { usersAndGroupsSlice } from './reducers/users-and-groups-slice';

const reducers = combineReducers({
  [menuSlice.name]: menuSlice.reducer,
  [notificationsSlice.name]: notificationsSlice.reducer,
  // [onboardingSlice.name]: onboardingSlice.reducer,
  [firstLoginSlice.name]: firstLoginSlice.reducer,
  // [chatSlice.name]: chatSlice.reducer,
  [filterSlice.name]: filterSlice.reducer,
  [userInfoSlice.name]: userInfoSlice.reducer,
  [themeSlice.name] : themeSlice.reducer,
  [usersAndGroupsSlice.name] : usersAndGroupsSlice.reducer,
  [knowledgeBasesSlice.name] : knowledgeBasesSlice.reducer,
});

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  blacklist: ['chat'],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER,
        ],
        // ignoredPaths: ['upload.uploads', 'mediaUpload.transcriptionBoxes'],
      },
    }),
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;